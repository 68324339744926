body {
    font-size: 1.6rem;
}

/* body, ion-app, ion-content {
    background: url('../assets/texture_bg.png') no-repeat center center fixed;
    background-size: cover;
  }

  ion-content {
    background: url('../assets/texture_bg.png') no-repeat center center fixed;
    background-size: cover;
  }

  #background-content {
    background: url('../assets/texture_bg.png') no-repeat center center fixed;
    background-size: cover;
  } */

/* ul {
    margin-top: 0.2em;
} */

input {
    background-color: var(--background);
    border: none;
    font-size: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, .2);
    padding: 0.675rem 0;
}

.app-space-for-scroll {
    height: 3.4rem;
}

.btn-default {
    display: inline-block;
    background-color: transparent;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, .3);
    padding: 13px 35px;
    border-radius: 30px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1em;
    cursor: pointer;
}

.small {
    font-size: x-small;
}

.font {
    font-family: var(--font-family);
}

ion-grid {
    padding: 1rem;

    @media (max-width: 576px) {
        padding: 0.1rem;
    }
}

ion-row {
    font-size: 1.2rem;
    color: #e1e1e1;
    //border-bottom: 1px solid #f5f5f554;
    border-bottom: 2px solid rgba(255, 255, 255, .2);

    &.header {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.6rem;
        border-bottom: 2px solid rgba(255, 255, 255, .4);


        ion-col {
            padding-top: .8rem;
            padding-bottom: .8rem;
        }
    }
}

ion-col {
    overflow: auto;
    padding-left: 0.5rem;
    border-right: 1px solid rgba(255, 255, 255, .2);

    &:last-child {
        border-right: none;
    }
}

/* ion-input > ion-input.input-disabled.sc-ion-input-md-h {
   opacity: 1;
} */


.spinner-wheel {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #474bff;
    background: linear-gradient(currentColor 0 0) center/100% 4.5px,
        linear-gradient(currentColor 0 0) center/4.5px 100%,
        radial-gradient(farthest-side, #0000 calc(100% - 6.7px), currentColor calc(100% - 5.6px)),
        radial-gradient(circle 6.7px, currentColor 94%, #0000 0);
    background-repeat: no-repeat;
    animation: spinner-mu2ebf 1s infinite linear;
    position: relative;
}

.spinner::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: inherit;
    transform: rotate(45deg);
}

@keyframes spinner-mu2ebf {
    to {
        transform: rotate(.5turn);
    }
}


/* -------------------------------- BOOTSTRAP ------------------------------- */
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}