
ion-content {
    //overflow: auto;
}

.label-text-wrapper {
    padding-bottom: 0.2em;
    margin-top: -0.6em;
}

ion-modal#crud-table-modal {
    --width: fit-content;
    --min-width: 20rem;
    --height: fit-content;
    //--border-radius: 6px;
    //--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }